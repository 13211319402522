body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes move {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 40%;
  }
  100% {
    transform: translateY(110vh);
    opacity: 10%;
  }
}

#bubbles {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffff;
  overflow: hidden;
}

#bubbles span {
  width: 30vmin;
  height: 5vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 65;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


#bubbles span:nth-child(0) {
  color: #B9ADEF;
  top: -100px;
  left: 22%;
  animation-duration: 45s;
  animation-delay: -24s;
  transform-origin: -21vw 4vh;
  box-shadow: -40vmin 0 5.275283379862315vmin currentColor;
}
#bubbles span:nth-child(1) {
  color: #64D5F1;
  top: -100px;
  left: 20%;
  animation-duration: 23s;
  animation-delay: -5s;
  transform-origin: -12vw -23vh;
  box-shadow: 40vmin 0 5.2243086044837135vmin currentColor;
}
#bubbles span:nth-child(2) {
  color: #64D5F1;
  top: -100px;
  left: 80%;
  animation-duration: 7s;
  animation-delay: -12s;
  transform-origin: 18vw 20vh;
  box-shadow: -40vmin 0 5.851906333560115vmin currentColor;
}
#bubbles span:nth-child(3) {
  color: #7BD08A;
  top: -100px;
  left: 96%;
  animation-duration: 40s;
  animation-delay: -44s;
  transform-origin: 21vw -23vh;
  box-shadow: 40vmin 0 5.2826706672101915vmin currentColor;
}
#bubbles span:nth-child(4) {
  color: #7BD08A;
  top: -100px;
  left: 77%;
  animation-duration: 12s;
  animation-delay: -17s;
  transform-origin: 20vw -8vh;
  box-shadow: 40vmin 0 5.28090664315844vmin currentColor;
}
#bubbles span:nth-child(5) {
  color: #EEC73B;
  top: -100px;
  left: 69%;
  animation-duration: 17s;
  animation-delay: -35s;
  transform-origin: 2vw 9vh;
  box-shadow: -40vmin 0 5.972166980175718vmin currentColor;
}
#bubbles span:nth-child(6) {
  color: #B9ADEF;
  top: -100px;
  left: 82%;
  animation-duration: 45s;
  animation-delay: -5s;
  transform-origin: -2vw -3vh;
  box-shadow: 40vmin 0 5.306610950616289vmin currentColor;
}
#bubbles span:nth-child(7) {
  color: #64D5F1;
  top: -100px;
  left: 1%;
  animation-duration: 40s;
  animation-delay: -34s;
  transform-origin: 2vw -16vh;
  box-shadow: 40vmin 0 5.180890076426782vmin currentColor;
}
#bubbles span:nth-child(8) {
  color: #64D5F1;
  top: -100px;
  left: 66%;
  animation-duration: 44s;
  animation-delay: -45s;
  transform-origin: -7vw 19vh;
  box-shadow: -40vmin 0 5.514224863679635vmin currentColor;
}
#bubbles span:nth-child(9) {
  color: #EEC73B;
  top: -100px;
  left: 51%;
  animation-duration: 47s;
  animation-delay: -34s;
  transform-origin: 1vw -3vh;
  box-shadow: 40vmin 0 5.681401225033132vmin currentColor;
}
#bubbles span:nth-child(10) {
  color: #B9ADEF;
  top: -100px;
  left: 22%;
  animation-duration: 16s;
  animation-delay: -3s;
  transform-origin: 20vw 18vh;
  box-shadow: 40vmin 0 5.160950052840531vmin currentColor;
}
#bubbles span:nth-child(11) {
  color: #64D5F1;
  top: -100px;
  left: 15%;
  animation-duration: 12s;
  animation-delay: -14s;
  transform-origin: 17vw 5vh;
  box-shadow: -40vmin 0 5.632505237860056vmin currentColor;
}
#bubbles span:nth-child(12) {
  color: #B9ADEF;
  top: -100px;
  left: 84%;
  animation-duration: 38s;
  animation-delay: -34s;
  transform-origin: -20vw 2vh;
  box-shadow: -40vmin 0 5.872963610096369vmin currentColor;
}
#bubbles span:nth-child(13) {
  color: #7BD08A;
  top: -100px;
  left: 76%;
  animation-duration: 31s;
  animation-delay: -41s;
  transform-origin: 1vw 22vh;
  box-shadow: -40vmin 0 5.841687916062982vmin currentColor;
}
#bubbles span:nth-child(14) {
  color: #64D5F1;
  top: -100px;
  left: 77%;
  animation-duration: 17s;
  animation-delay: -42s;
  transform-origin: -21vw 12vh;
  box-shadow: -40vmin 0 5.090471991302352vmin currentColor;
}
#bubbles span:nth-child(15) {
  color: #B9ADEF;
  top: -100px;
  left: 5%;
  animation-duration: 20s;
  animation-delay: -38s;
  transform-origin: 21vw 4vh;
  box-shadow: 40vmin 0 5.64522801534352vmin currentColor;
}
#bubbles span:nth-child(16) {
  color: #7BD08A;
  top: -100px;
  left: 54%;
  animation-duration: 32s;
  animation-delay: -29s;
  transform-origin: -2vw 15vh;
  box-shadow: 40vmin 0 5.799002046280657vmin currentColor;
}
#bubbles span:nth-child(17) {
  color: #7BD08A;
  top: -100px;
  left: 30%;
  animation-duration: 51s;
  animation-delay: -10s;
  transform-origin: 2vw 20vh;
  box-shadow: 40vmin 0 5.0969091015245755vmin currentColor;
}
#bubbles span:nth-child(18) {
  color: #64D5F1;
  top: -100px;
  left: 40%;
  animation-duration: 21s;
  animation-delay: -8s;
  transform-origin: 12vw -11vh;
  box-shadow: -40vmin 0 5.914090244446522vmin currentColor;
}
#bubbles span:nth-child(19) {
  color: #B9ADEF;
  top: -100px;
  left: 92%;
  animation-duration: 22s;
  animation-delay: -50s;
  transform-origin: 25vw -5vh;
  box-shadow: -40vmin 0 5.505213868932892vmin currentColor;
}
